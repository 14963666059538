<template>
  <div class="order">
    <div class="header">B/L Number: {{ order.bl_no }}</div>
    <div class="info">
      <div>{{ order.pol }} - {{ order.pod }}</div>
    </div>
    <div class="route">
      <div class="route_info" v-for="item in order.route" :key="item._id">
        <div class="route_name">
          {{ item.point }}
        </div>
        <div class="route_time">
          <div class="route_time_info">EST: {{ item.plan_time }}</div>
          <div class="route_time_info">
            ACT: <span v-if="item.actual_time">{{ item.actual_time }}</span>
            <span v-else>Unknown</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import OrderTracking from "../components/OrderTracking.vue";
import api from "../api/index.js";
export default {
  name: "Order",
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      order: {},
    };
  },
  mounted() {
    this.loadOrder();
  },
  methods: {
    async loadOrder() {
      let order = await api.getOrder(this.id);
      console.log(order);
      this.order = order;
    },
  },
};
</script>


<style scoped>
.header {
  padding: 10px 0;
  background: #77a7d8;
  color: rgb(11, 67, 119);
  padding: 10px 0;
}
.info {
  background: #2c3e50;
  color: #fff;
  padding: 10px 0;
}
.route_info {
  display: flex;
  border-bottom: 1px solid #ccc;
  justify-content: space-evenly;
}
.route_name {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.route_time {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.route_time_info:first-child {
  margin-bottom: 5px;
}
</style>